import type { NextPage } from "next";
import Head from "next/head";
import styles from "../styles/Home.module.css";

const Home: NextPage = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>Venue API</title>
        <meta name="description" content="Venue API by Lalena" />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-192x192.png"
          sizes="96x96"
        />
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>
          Welcome to <a href="https://api.venue.run">Venue API</a>
        </h1>

        <div className={styles.grid}>
          <a href="https://www.venue.run" className={styles.card}>
            <h2>Learn &rarr;</h2>
            <p>Learn about Venue API</p>
          </a>
          <a href="https://api.venue.run/graphql" className={styles.card}>
            <h2>Playground &rarr;</h2>
            <p>View and test the API</p>
          </a>
        </div>
      </main>
    </div>
  );
};

export default Home;
